import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache();

/**
 * Localhost: http://localhost:4000
 * Prod - https://e9vr6ohk94.execute-api.ap-southeast-1.amazonaws.com/prod/graphql
 */
const httpLink = new HttpLink({
  uri: 'https://e9vr6ohk94.execute-api.ap-southeast-1.amazonaws.com/prod/graphql',
});

const authLink = setContext(async (_, { headers }) => {
  const query = Auth.currentSession();

  return new Promise((resolve) => {
    query.then(({ accessToken: { jwtToken } }) => {
      resolve({
        headers: {
          ...headers,
          authorization: jwtToken ? `Bearer ${jwtToken}` : '',
        },
      });
    });
  });
});

const apolloClient = new ApolloClient({
  cache,
  link: from([authLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

/**
 * Starts up ApolloClient only when user is logged in.
 */
const Connect = ({ children }) => (
  <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
);

Connect.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Connect;
