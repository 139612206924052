import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { Auth } from 'aws-amplify';

import Utils from '../../utils';
import SGCCSquareLogo from '../../assets/images/sgcc-logo.png';
import LogoutIcon from '../../assets/icons/logout.svg';
import CalendarIcon from '../../assets/icons/calendar.svg';
import ExportIcon from '../../assets/icons/export.svg';
import AddTimeIcon from '../../assets/icons/add_time.svg';

import $ from '../../styles/global';

const Container = styled.div`
  padding: ${$.layout().padding5}px ${$.layout().padding3}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${$.color.gray};

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    padding: ${$.layout().padding5}px calc((100% - 1100px) / 2);
  }
  // #endregion
`;

const LeftToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoContainer = styled(Link)`
  display: inline-block;
  text-decoration: none;

  & > img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`;

const Username = styled.div`
  font-size: 15px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    max-width: 100px;
    width: 100%;
    font-size: 15px;
    ${$.ellipsis};
  }
  // #endregion
`;

const NavigationLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > div {
    &:not(:last-child) {
      margin-right: ${$.layout().margin4}px;
    }

    & > svg {
      fill: ${$.color.blue5};
      transition: opacity 0.2s ease;
      width: 24px;
      height: 24px;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
`;

const Navbar = () => {
  const [group, setGroup] = useState(Utils.STUDENTS_GROUP);
  const [username, setUsername] = useState('');

  useEffect(() => {
    Utils.getGroupName().then((res) => {
      setGroup(res);
    });

    Auth.currentAuthenticatedUser().then(({ attributes }) => {
      setUsername(attributes.name);
    });
  }, []);

  return (
    <Container>
      <LeftToolbar>
        <LogoContainer to="/" title="Home">
          <img src={SGCCSquareLogo} alt="Home" />
        </LogoContainer>
        <Username>{`Hi, ${username}`}</Username>
      </LeftToolbar>
      <NavigationLinks>
        {group === Utils.STUDENTS_GROUP && (
          <div title="Attendance Report">
            <CalendarIcon
              onClick={() => {
                navigate('/report/');
              }}
            />
          </div>
        )}
        {group === Utils.ADMIN_GROUP && (
          <div title="Export Reports">
            <ExportIcon
              onClick={() => {
                navigate('/instructor-report/');
              }}
            />
          </div>
        )}
        {[Utils.ADMIN_GROUP, Utils.INSTRUCTORS_GROUP].includes(group) && (
          <div title="Manage Extra Hours">
            <AddTimeIcon
              onClick={() => {
                navigate('/extra-hours/');
              }}
            />
          </div>
        )}
        <div title="Logout">
          <LogoutIcon
            onClick={() => {
              Auth.signOut().catch((e) => {
                /* eslint-disable-next-line */
                console.log('Logout error: ', e);
              });
            }}
          />
        </div>
      </NavigationLinks>
    </Container>
  );
};

export default Navbar;
