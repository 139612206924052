import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Amplify from 'aws-amplify';
import {
  SignIn,
  ConfirmSignIn,
  RequireNewPassword,
  Authenticator,
} from 'aws-amplify-react';

import Navbar from './Navbar';
import ApolloConnect from './ApolloConnect';
import awsConfig from '../../../aws-config';
import SGCCLogo from '../../assets/icons/logo.svg';

import $ from '../../styles/global';

Amplify.configure(awsConfig);

const Body = styled.div`
  & > section {
    width: calc(100% - ${24 * 2}px);
    margin: 0 24px;
  }

  // NOTE: Desktop and above
  // #region
  @media ${$.device.desktop} {
    & > section {
      width: 970px;
      margin: 0 auto;
    }
  }
  // #endregion
`;

const LogoContainer = styled.div`
  & > div {
    width: calc(100vw - ${$.layout().margin3 * 2}px);
    margin: 100px ${$.layout().margin3}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > svg {
      width: 300px;
    }

    // NOTE: Mobile and above
    // #region
    @media ${$.device.mobile} {
      & > svg {
        max-width: 250px;
        width: 100%;
      }
    }
    // #endregion
  }
`;

const amplifyTheme = {
  formContainer: {
    margin: '0 auto',
    maxWidth: '300px',
    width: '100%',
    textAlign: 'center',
  },
  formSection: {
    backgroundColor: '#ffffff',
    position: 'relative',
    borderRadius: '10px',
    fontFamily: 'Helvetica Neue',
    padding: '32px 20px',
    marginBottom: '20px',
    display: 'inline-block',
    width: 'calc(100% - 40px)',
    textAlign: 'left',
    border: `1px solid ${$.color.gray}`,
  },
  formField: {
    marginBottom: '45px',
  },
  sectionHeader: {
    color: $.color.blue4,
    marginBottom: '40px',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: '500',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  inputLabel: {
    fontSize: '14px',
    marginBottom: '8px',
    color: $.color.blue5,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontWeight: 'bold',
  },
  hint: {
    fontSize: '13px',
    color: $.color.black2,
    marginTop: '20px',
    textAlign: 'center',
  },
  input: {
    color: $.color.blue4,
    borderRadius: '10px',
    padding: '8px 16px',
    fontSize: '16px',
    outline: 'none',
    display: 'block',
    width: 'calc(100% - 32px - 2px)',
    lineHeight: '1.3em',
    marginBottom: '20px',
    backgroundColor: '#fff',
    border: `1px solid ${$.color.gray}`,
  },
  a: {
    color: $.color.orange4,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  button: {
    fontSize: '16px',
    borderRadius: '10px',
    fontWeight: 'bold',
    outline: 'none',
    padding: '15px',
    width: '100%',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    display: 'inline-block',
    marginBottom: '0',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: $.color.orange3,
    border: 'none',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  sectionFooter: {
    width: '100%',
  },
  sectionFooterSecondaryContent: {
    alignSelf: 'center',
    fontSize: '14px',
    marginTop: '15px',
    display: 'block',
  },
  toast: {
    backgroundColor: $.color.black,
    color: $.color.white,
    padding: '16px',
    textAlign: 'center',
  },
};

const Layout = ({ children }) => {
  const [hideLayout, setHideLayout] = useState(true);

  return (
    <Authenticator
      hideDefault
      theme={amplifyTheme}
      onStateChange={(authState) => {
        switch (authState) {
          case 'signedIn':
            setHideLayout(false);
            break;
          case 'signIn':
            setHideLayout(true);
            break;
          default:
            break;
        }
      }}
    >
      <LogoContainer>
        {hideLayout === true ? (
          <div>
            <SGCCLogo />
          </div>
        ) : (
          ''
        )}
      </LogoContainer>
      <SignIn />
      <ConfirmSignIn />
      <RequireNewPassword />
      <div>
        {hideLayout === false ? (
          <ApolloConnect>
            <Navbar />
            <Body>{children}</Body>
          </ApolloConnect>
        ) : (
          ''
        )}
      </div>
    </Authenticator>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
